<template>
  <router-view id="container"></router-view>
</template>

<script>
export default {
  name: 'TrucksView',
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
  .container, .v-tabs-items, .v-tabs, .v-item-group{
    height: 100% !important;
  }
  #container{
    padding-top: 2px;
  }
</style>
